import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/SEO/seo';

import * as styles from '../styles/components.module.css';

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { markdownRemark } = data; // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark;
    return (
        <Layout>
            <SEO pageTitle="Home"/>
            <div className={`${ styles.markdownContainer }`}>
                <div className={`${ styles.markdown }`}>
                    <h1><Link to='/' state={{ ready: true }}><i className="fas fa-arrow-left"></i></Link>{frontmatter.title}</h1>
                    <div
                        className={`${ styles.markdownContent }`}
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </div>
            </div>
        </Layout>
    );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;

Template.propTypes = {
    data: PropTypes.object,
};
